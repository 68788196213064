<template>
  <div class="dialog-form">
    <el-dialog v-if="isShow" v-model="isShow" :title="title" width="30%" @close="close">
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="120px"
        class="demo-ruleForm"
        label-suffix="："
      >
      <el-form-item label="销售人员" prop="saleIds">
        <el-select v-model="ruleForm.saleIds" multiple class="m-2" placeholder="请选择" size="large">
          <el-option
            v-for="item in shopTeacher"
            :key="item.userId"
            :label="item.userRealname"
            :value="item.userId"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
      <template #footer>
        <el-button @click="close">取消</el-button>
        <el-button @click="handleSaveClick(ruleFormRef)" type="primary">保存</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { editOrderSale } from '@/service/main/order'
import { ElMessage } from 'element-plus'
export default defineComponent({
  props: {
  },
  components: {
  },
  setup(props, { emit }) {
    const store = useStore()
    const ruleForm = reactive({
      saleIds:[],
      orderId:null
    })
    const rules = {
      saleIds: [{ required: true, message: '请选择', trigger: 'change' }],
    }
    const ruleFormRef = ref()
    const isShow = ref(false)
    const title = ref('修改销售人员')
    const open = (data) =>{
      ruleForm.orderId = data.orderId
      isShow.value = true
      ruleForm.saleIds=data.saleUserIds
      initPage(data.shopId)
    }
    const close = () =>{
      isShow.value = false
      ruleFormRef.value.resetFields()
      ruleForm.orderId=''
      ruleForm.saleIds=[]
    }

    const handleSaveClick = async(formEl) => {
      if (!formEl) return
        await formEl.validate(async(valid, fields) => {
          if (valid) {
            let res = await editOrderSale({...ruleForm})
            if (res.code == 0) {
              ElMessage({
                message: '修改成功!',
                type: 'success',
              })
              emit('refresh')
              close()
            }else{
              ElMessage({
                message: res.msg,
                type: 'error',
              })
            }
          }
        })
    }
    const shopTeacher = computed(() => store.state.order.shopTeacher)
    const initPage = (shopId) => {
      store.dispatch('order/getShopTeacherAction', shopId)
    }

    return {
      handleSaveClick,
      isShow,
      ruleForm,
      title,
      ruleFormRef,
      open,
      rules,
      close,
      shopTeacher
    }

  }
})
</script>

<style scoped lang="less">
.dialog-form {
  z-index: 999;

  .teacher {
    display: flex;
    flex-wrap: wrap;

    .active {
      background-color: rgb(255, 162, 68);
      color: white;
    }

    .item {
      border-radius: 6px;
      cursor: pointer;
      padding: 0 10px;
      width: 140px;
      line-height: 45px;
      margin: 0 10px;
      border: 1px solid rgba(217, 217, 217, .8);
    }

  }

  &:deep(.el-scrollbar) {
    padding-right: 20px;
  }



}
</style>
